import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Media } from "@ryerson/frontend.layout";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { Grid, Column, Row } from "@ryerson/frontend.layout";
import { Breadcrumb } from "@ryerson/frontend.navigation";
import { Typography } from "@ryerson/frontend.typography";
import { Video } from "@ryerson/frontend.media";
import { VideoWrapperProps } from "@components/Helper/common";
import { getVideoPadding } from "@components/Helper/commonStyles";

export interface GetStartedDetails {
	title: string[];
	subheader: string[];
	description: string[];
	mainVideo: string;
	mainVideoLabel: string;
	mainVideoImageUrl: string;
	subVideos: string[];
	subVideoLabels: string[];
	subVideoImageUrls: string[];
}

export interface GetStartedProps {
	content: GetStartedDetails;
}

const BreadcrumbContainer = styled.div`
	margin-top: 32px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				margin-top: 8px;
			}
		`;
	}}
`;

const HeaderSpacer = styled.div`
	height: 40px;
`;

const SubHeaderTextContainer = styled.div`
	margin-bottom: 66px;
	margin-top: 72px;
`;

const MainVideoWrapper = styled.div`
	max-width: 766px;
	height: 421px;
	margin-bottom: 65px;
	margin-top: 52px;
	${(props: VideoWrapperProps) => {
		const { buttonOrientation = "left", buttonSize = "md" } = props;
		return css`
			${buttonOrientation === "center"
				? 0
				: getVideoPadding(buttonOrientation, false, buttonSize)};
		`;
	}}
`;

const ImageWrapper = styled.div`
	max-width: 701px;
	height: 421px;
	margin-bottom: 65px;
	margin-top: 52px;
	${(props: any) => {
		const { theme } = props;
		return `
		@media screen and (max-width: ${theme.breakpoints.lg}){
				height: 230px;
				max-width: 300px;
				margin: 0 auto;
		}
		`;
	}}
`;

const SubVideoWrapper = styled.div`
	width: 350px;
	height: 180px;
	margin-right: 50px;
	box-sizing: border-box;
	${(props: VideoWrapperProps) => {
		const { buttonOrientation = "left", buttonSize = "md" } = props;
		return css`
			${buttonOrientation === "center"
				? 0
				: getVideoPadding(buttonOrientation, false, buttonSize)};
		`;
	}}
`;

const SubVideoTextContainer = styled.div`
	max-width: 300px;
	margin-top: 32px;
	margin-bottom: 85px;
`;

const MobileVideoSectionContainer = styled.div`
	margin-bottom: 30px;
	:last-of-type {
		margin-bottom: 0px;
	}
`;

const MobileVideoWrapper = styled.div`
	height: 230px;
	max-width: 300px;
	margin: 0 auto;
	margin-bottom: 30px;
	${(props: VideoWrapperProps) => {
		const { buttonOrientation = "left", buttonSize = "md" } = props;
		return css`
			${buttonOrientation === "center"
				? 0
				: getVideoPadding(buttonOrientation, true, buttonSize)};
		`;
	}}
`;

const GetStarted: React.FC<GetStartedProps> = ({ content }) => {
	const { theme } = useTheme();

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection type={"tertiary"}>
					<BreadcrumbContainer theme={theme}>
						<Breadcrumb type="tertiary" size="xs" />
					</BreadcrumbContainer>
					<HeaderSpacer></HeaderSpacer>
					{content.title.map((string: string, index: number) => {
						return (
							<Typography
								variant="div"
								color={theme.colors.primary.white}
								css={{ fontSize: "56px" }}
								key={index}
							>
								{string}
							</Typography>
						);
					})}
					<HeaderSpacer></HeaderSpacer>
				</ContentSection>
				<ContentSection type="secondary">
					<Grid>
						<Row>
							<Column lg={8}>
								<MainVideoWrapper buttonOrientation="right">
									{content.mainVideo && content.mainVideo.length > 0 ? (
										<Video
											videoId={content.mainVideo}
											buttonOrientation="right"
											imageUrl={content.mainVideoImageUrl}
											text={content.mainVideoLabel}
										></Video>
									) : (
										<ImageWrapper theme={theme}>
											<img
												src={content.mainVideoImageUrl}
												css={css`
													width: 100%;
													height: 100%;
													object-fit: cover;
												`}
											/>
										</ImageWrapper>
									)}
								</MainVideoWrapper>
							</Column>
							<Column lg={4}>
								<SubHeaderTextContainer>
									<Typography variant="h3">
										{content.subheader.map((string: string, index: number) => {
											return (
												<Typography
													variant="div"
													type="primary"
													css={{ fontSize: "28px" }}
													color={"#002255"}
													key={index}
													font="inherit"
												>
													{string}
												</Typography>
											);
										})}
									</Typography>
								</SubHeaderTextContainer>
								{content.description.map((string: string, index: number) => {
									return (
										<Typography
											variant="p"
											type="primary"
											key={index}
											css={{ lineHeight: "22px" }}
										>
											{string}
										</Typography>
									);
								})}
							</Column>
						</Row>
						<Row>
							<Column lg={8}>
								<Flex>
									{content.subVideos ? (
										content.subVideos.map((url: string, index: number) => {
											return (
												<FlexItem key={index}>
													<SubVideoWrapper
														buttonOrientation="right"
														buttonSize="sm"
													>
														<Video
															videoId={url}
															buttonOrientation="right"
															buttonSize="sm"
															imageUrl={
																content.subVideoImageUrls[index]
															}
														></Video>
													</SubVideoWrapper>
													{content.subVideoLabels ? (
														<SubVideoTextContainer>
															<Typography
																variant="h3"
																type="primary"
																weight={"bolder"}
																color={"#002255"}
															>
																{content.subVideoLabels[index]}
															</Typography>
														</SubVideoTextContainer>
													) : (
														<></>
													)}
												</FlexItem>
											);
										})
									) : (
										<></>
									)}
								</Flex>
							</Column>
							<Column lg={4}>
								<></>
							</Column>
						</Row>
					</Grid>
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection type={"tertiary"}>
					<BreadcrumbContainer theme={theme}>
						<Breadcrumb type="tertiary" size="xs" />
					</BreadcrumbContainer>
					<Typography
						variant="h1"
						type="tertiary"
						css={{ marginTop: "19px", marginBottom: "23px" }}
					>
						{content.title.join(" ")}
					</Typography>
					<Typography variant="h4" type="tertiary">
						{content.subheader.join(" ")}
					</Typography>
					{content.description.map((string: string, index: number) => {
						return (
							<Typography
								variant="p"
								size="md"
								color={theme.colors.primary.white}
								key={index}
							>
								{string}
							</Typography>
						);
					})}
				</ContentSection>
				<ContentSection type="primary">
					{content.mainVideo && content.mainVideo.length > 0 ? (
						<MobileVideoWrapper buttonOrientation="top-right">
							<Video
								videoId={content.mainVideo}
								buttonOrientation="top-right"
								text={content.mainVideoLabel}
								imageUrl={content.mainVideoImageUrl}
							></Video>{" "}
						</MobileVideoWrapper>
					) : (
						<ImageWrapper theme={theme}>
							<img
								src={content.mainVideoImageUrl}
								css={css`
									width: 100%;
									height: 100%;
									object-fit: cover;
								`}
							/>
						</ImageWrapper>
					)}

					{content.subVideos ? (
						content.subVideos.map((url: string, index: number) => {
							return (
								<MobileVideoSectionContainer key={index}>
									<MobileVideoWrapper key={index} buttonOrientation="top-right">
										<Video
											videoId={url}
											buttonOrientation="top-right"
											text={content.subVideoLabels[index]}
											imageUrl={content.subVideoImageUrls[index]}
										></Video>
									</MobileVideoWrapper>
								</MobileVideoSectionContainer>
							);
						})
					) : (
						<></>
					)}
				</ContentSection>
			</Media>
		</>
	);
};

export default GetStarted;
